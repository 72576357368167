<template>
  <div>
    <v-row class="ma-0" align="center">
      <v-col cols="12 pa-0">
        <div>
          <multiselect
            selected-label=""
            deselect-label=""
            deselect-group-label=""
            select-group-label=""
            select-label=""
            v-model="selections"
            :options="data"
            :multiple="true"
            group-values="services"
            group-label="name"
            :group-select="true"
            placeholder="Selecciona y agrega los servicios que ofreces"
            track-by="id"
            label="name"
            :close-on-select="false"
            outlined
            color="#7319D5"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <div v-if="values.length &amp;&amp; !isOpen" class="multiselect__tags-wrap" style="">
                <span
                  v-for="(item, itemIndex) in values.slice(0, 3)"
                  :key="itemIndex"
                  class="multiselect__tag__template multiselect__tag"
                >
                  <span>{{ item.name }}</span>
                </span>
                <span v-if="values.length > 3" class="multiselect__single multiselect__tag"
                  >(+{{ values.length - 3 }})</span
                >
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <span v-if="props.option.name">
                <div v-if="serviceSelected(props.option.id)" class="v-list-item__action mr-1 ml-6 my-2">
                  <div class="v-simple-checkbox">
                    <div class="v-input--selection-controls__ripple primary--text" />
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-checkbox-marked theme--light" />
                  </div>
                </div>
                <div v-if="!serviceSelected(props.option.id)" class="v-list-item__action mr-1 ml-6 my-2">
                  <div class="v-simple-checkbox">
                    <div class="v-input--selection-controls__ripple primary--text" />
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-checkbox-blank-outline theme--light" />
                  </div>
                </div>
                {{ props.option.name }}
              </span>
              <span v-if="props.option.$groupLabel">
                <div v-if="categorySelected(props.option.$groupLabel)" class="v-list-item__action mr-1 my-2">
                  <div class="v-simple-checkbox">
                    <div class="v-input--selection-controls__ripple primary--text" />
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-checkbox-marked theme--light" />
                  </div>
                </div>
                <div v-if="!categorySelected(props.option.$groupLabel)" class="v-list-item__action mr-1 my-2">
                  <div class="v-simple-checkbox">
                    <div class="v-input--selection-controls__ripple primary--text" />
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-checkbox-blank-outline theme--light" />
                  </div>
                </div>
                {{ props.option.$groupLabel }}
              </span>
            </template>
            <span slot="noResult">Oops! No encontramos ningun elemento</span>
          </multiselect>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Choice",
  components: { Multiselect },
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
    tag: {
      type: String,
      default: "",
      required: false,
    },
    dataSelections: {
      type: [Array, Object],
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      selections: [],
      selected: "",
    };
  },
  created() {
    setTimeout(() => {
      if (this.dataSelections) {
        this.dataSelections.forEach((e) => {
          let elem = this.data.find((d) => d.id == e.categoryId);
          elem = elem.services.find((d) => d.id == e.id);
          if (elem) {
            this.selections.unshift(elem);
            this.selected = "";
          }
        });
      }
    }, 500);
  },
  methods: {
    clean() {
      this.selections = [];
    },
    serviceSelected(id) {
      let temp = this.selections.find((s) => s.id == id);
      return temp;
    },
    categorySelected(name) {
      let temp = this.data.find((c) => c.name == name);
      if (temp) {
        let services = temp.services;
        for (let i = 0; i < services.length; i++) {
          const service = services[i];
          if (!this.serviceSelected(service.id)) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    removeSelected(id) {
      this.selections = this.selections.filter((e) => e.id != id);
    },
    addSelected() {
      if (this.selected) {
        let elem = this.data.find((e) => e.id == this.selected);
        if (elem) {
          this.selections.unshift(elem);
          this.selected = "";
        }
      }
    },
  },
  watch: {
    selections() {
      this.$emit("array", this.selections);
    },
  },
  computed: {
    notSelected() {
      let hash = {};
      this.selections.forEach((e) => {
        hash[e.id] = e.name;
      });
      return this.data.filter((e) => !(e.id in hash));
    },
  },
};
</script>
